import React from 'react';
import { useLocation } from 'react-router-dom';
import ArticlePropertiesProvider from './../../providers/article-properties.provider';
import ArticleContent from '../../components/article-content/article-content.component';

import './article.styles.scss';

const Article = ({match}) => {
    const slug = match.params.slug;
    const location = useLocation();
    return (
        <div className="article-page">
            <ArticlePropertiesProvider slug={slug}>
                <ArticleContent {...location.state} />
            </ArticlePropertiesProvider>
        </div>
    )
}

export default Article;