import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation  } from "react-router-dom";
import './navigation.styles.scss';

const Navigation = () => {
    let location = useLocation();

    return (
    <nav className={location.pathname === "/" ? "navigation navigation--home" : "navigation"}>
        <ul className="navigation__list">
            <li className="navigation__item">
                <Link className={location.pathname === "/" ? "btn navigation__link navigation__link--active" :"navigation__link navigation__link--effect btn"} to="/">home</Link>
            </li>
            <li className="navigation__item">
                <Link className={location.pathname.startsWith("/articles") ? "btn navigation__link navigation__link--active" :"navigation__link navigation__link--effect btn"} to="/articles">articles</Link>
            </li>
            <li className="navigation__item">
                <Link className={location.pathname === "/about" ? "btn navigation__link navigation__link--active" :"navigation__link navigation__link--effect btn"} to="/about">about</Link>
            </li>
            <li className="navigation__item">
                <Link className={location.pathname === "/contact" ? "btn navigation__link navigation__link--active" :"navigation__link navigation__link--effect btn"} to="/contact">contact</Link>
            </li>
        </ul>
    </nav>
)}

export default Navigation;