import React, {createContext, useState, useEffect} from 'react';
import {getPublishedCategories} from '../services/articles.actions';

export const CategoriesContext = createContext({
    allCategories: [],
    selectedCategories: [],
    selectCategories: ()=> {},
    isFetching: true,
    error: ""
});

const CategoriesProvider = ({children}) => {
    const [allCategories, setCategories] = useState([]);
    const [selectedCategories, selectCategories] = useState([]);
    const [error, setError] = useState("");
    const [isFetching, setIsFetching] = useState(true);
    
    useEffect(() => {
        getPublishedCategories().then(response => {
            if (response.status === "fail") {
                setError(response.message)
            } else if (response.data) {
                setCategories(response.data);
                selectCategories(response.data);
            }
            setIsFetching(false);
        });
    }, []);
    return(
        <CategoriesContext.Provider value={{
                allCategories, 
                selectedCategories,
                selectCategories,
                isFetching, 
                error
            }}>
            {children}
        </CategoriesContext.Provider>
    )
}

export default CategoriesProvider;