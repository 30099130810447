import React from 'react';
import { Link } from 'react-router-dom';
import  DateFormat from './../../utils/date-format';

import './latest-stuff-box.styles.scss';

const LatestStuffBox = ({title, description, categories, createdAt, slug}) => {
    return (
            <Link className="btn btn--effect latest-stuff-box" to={{pathname:`/articles/${slug}`, state: {title, categories, createdAt}}}>
                <div className="latest-stuff-box__header">
                    <div className="latest-stuff-box__date">
                        <span>{DateFormat(createdAt)}
                        </span>
                    </div>
                    <div className="latest-stuff-box__categories">
                        {categories.map(c => 
                            <div className="latest-stuff-box__category" key={c}><span className="category-text">{c}</span></div>
                        )}
                    </div>
                </div>
                <div className="latest-stuff-box__title box-title-text"><span>{title}</span></div>
                <div className="latest-stuff-box__description"><p>{description}</p></div>
                <div className="latest-stuff-box__more"></div>
            </Link>
    )
}

export default LatestStuffBox;