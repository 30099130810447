import React from 'react';

import Home from './pages/home/home.component';
import About from './pages/about/about.component';
import Contact from './pages/contact/contact.component';
import Article from './pages/article/article.component';
import Articles from './pages/articles/articles.component';
import Footer from './components/footer/footer.component';
import Navigation from './components/navigation/navigation.component';
import ScrollToTop from './utils/scroll-to-top';
import { Switch, Route } from 'react-router-dom';

import './styles/main.scss';
import './App.scss';

function App() {

  return (
    <div className="App">
      <Navigation></Navigation>
      <ScrollToTop />
      <div className="App__main">
        <Switch>
          <Route exact path='/' component={Home} />          
          <Route exact path='/contact/' component={Contact} />
          <Route exact path='/about/' component={About} />
          <Route exact path='/articles/' component={Articles} />
          <Route exact path='/articles/:slug' component={Article} />
        </Switch>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
