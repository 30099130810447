import React from 'react';
import { Link } from 'react-router-dom';
import  DateFormat from './../../utils/date-format';

import './single-article-result.styles.scss';

const SingleArticleResult = ({title,description,createdAt,categories,slug}) => (
       <Link className="btn single-article-result" to={{pathname:`/articles/${slug}`, state: {title, categories, createdAt}}}>
            <div className="single-article-result__date">
                <span>{DateFormat(createdAt)}</span>
            </div>
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="single-article-result__categories">
                {categories.map(cat =><div key={cat} className="single-article-result__category">{cat}</div>)}
            </div>
        </Link>
)
export default SingleArticleResult;
