import React, {useContext} from 'react';
import LatestStuffBox from '../latest-stuff-box/latest-stuff-box.component';
import {ArticlesCollectionContext} from '../../providers/articles-collection.provider';

import './latest-stuff-list.styles.scss'

const LatestStuffList = () => {
    const {articles, isFetching} = useContext(ArticlesCollectionContext);
    return (
        <div className="latest-stuff-list">
            { !isFetching 
                ? articles.map(article => <LatestStuffBox {...article} key={article._id} />)
                : <span>Loading</span>  
            }
        </div>
    )
}

export default LatestStuffList;