import React from 'react';
import CategoriesProvider from './../../providers/categories.provider';
import CategoriesSelection from './../../components/categories-selection/categories-selection.component';
import ArticlesResult from './../../components/articles-result/articles-result.component';

import './articles.styles.scss';

const Articles = () => {
    return (
        <div className="articles">
            <div className="articles__header page-header">
                <h1 className="article-title">Blog posts</h1>
            </div>
            <div className="page-content">
                <CategoriesProvider>
                    <CategoriesSelection />
                    <ArticlesResult />
                </CategoriesProvider>
            </div>
        </div>
    )
}

export default Articles;