import React, {useEffect, useState, useContext} from 'react';
import SingleArticleResult from './../single-article-result/single-article-result.component';
import {CategoriesContext}  from './../../providers/categories.provider';
import {getPublishedArticles} from './../../services/articles.actions';

import './articles-result.styles.scss';

const ArticlesResult = () => {
    const [articles, setArticles] = useState([]);
    const {selectedCategories} = useContext(CategoriesContext);
    const [isFetching, setFetchingStatus] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        if (selectedCategories !== null && selectedCategories.length > 0)
        {
            getPublishedArticles(1,5, selectedCategories).then(response => {
                if (response.status === "fail") {
                    setError(response.message)
                } else if (response.data) {
                    setArticles(response.data);
                }
            });
        }
        setFetchingStatus(false);
    }, [selectedCategories]);
    return (
        <div className="articles-result">
            {isFetching 
                ? <span>Loading</span> 
                : error ? <span>{error}</span>
                : articles.map(article => <SingleArticleResult key={article._id} {...article}></SingleArticleResult>)}
        </div>
    )
}

export default ArticlesResult;