import React from 'react';
import './about.styles.scss';

const About = () => (
    <div className="about">
        <header className="page-header">
            <h1 className="article-title">About</h1>
        </header>
        <main className="about__main page-content">
            <h2>Why am I doing this?</h2>
            <i>
            Have you ever had a problem that you know you had encountered before but you totally forgot how you solved it before? Have you ever been asked a question in an interview that you remember you had been asked before but you have no clue what is the answer for it?
            </i><br />
            <p>I have been there once and again and it made me feel I'm a bad professional developer. I know deep inside that I should take notes somewhere, write it with my own words as to explain it to a beginner. I am always in a rush to see my application working. Now that I wrote the right code to make everything works fine, I assume that I understand the subtlety of the technical solution until I face it again and find myself searching for the very same solution and beating myself up for my lack of diligence. </p>
            <br />
            <h2>Where do I want to go?</h2>
            <p>From now on, I will write about the stuff I learn in the lab and I will share stories from my past experiences to finally have a collection of technical articles that I can go back to when I need it. This will also be a good help for the visitors of this website if they are on their own journey to create web applications or to learn coding. </p>
            <br />
            <p>In this process, I am aiming to be a better developer and a better writer as well as to grow my professional network (which is virtually empty at the time I am writing this sentence) and be involved in the community by participating in projects or by helping fellow developers to achieve their goals.</p>
            <br />
            <h2>Where am I?</h2>
            <p>I have ten years of experience in web development but when you work in consultancy you find yourself in between projects longer than you wish for. 
                I don't know the exact number of years of effective experience I have but I can attest to my skills and knowledge by the content of this website. I have used professionally .Net, C#, SharePoint, Sitecore, and I am in my lab working on side projects with Angular, React and NodeJs.
                I am eager to learn more and eager to work on new projects, so please don't hesitate to contact me.</p>
            <br />
            </main>
    </div>
)

export default About;