import React, {createContext, useState, useEffect} from 'react';
import {getArticleBySlug} from './../services/articles.actions';

export const ArticlePropertiesContext = createContext({
    article: {},
    error: "",
    isFetching: true
});

const ArticlePorpertiesProvider = ({children, slug}) => {
    const [article, setArticle] = useState({});
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        getArticleBySlug(slug).then(response => {
            if (response.status === "fail") {
                setError(response.message)
            } else if (response.data) {
                setArticle(response.data);
            }
            setIsFetching(false);
        });
    }, [slug]);

    return(
        <ArticlePropertiesContext.Provider value={{article, isFetching, error}}>
            {children}
        </ArticlePropertiesContext.Provider>
    )
}

export default ArticlePorpertiesProvider;