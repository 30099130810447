const articlesApiUrl = process.env.REACT_APP_ARTICLES_API;

export const getPostById = async (postId) => {
    const res = await fetch(`${articlesApiUrl}${postId}`);
    return await res.json();
}

export const getMostRecentPublishedArticles = async (countArticles) => {
    const fields = "id,title,description,createdAt,categories,slug";
    //const categories = "angular,react,javascript";
    const res = await fetch(`${articlesApiUrl}?limit=${countArticles}&fields=${fields}&published=true`);
    return await res.json();
}

export const getPublishedCategories = async () => {
    const res = await fetch(`${articlesApiUrl}/allcategories`);
    return await res.json();
}

export const getArticleBySlug = async (slug) => {
    const res = await fetch(`${articlesApiUrl}/slug/${slug}`);
    return await res.json();
}

export const getPublishedArticles = async (page, limit, categories) => {
    const fields = "title,description,createdAt,categories,slug";
    const res = await fetch(`${articlesApiUrl}?limit=${limit}&page=${page}&fields=${fields}&published=true&categories=${categories}`);
    return await res.json();
}