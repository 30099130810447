import React, {createContext, useState, useEffect} from 'react';
import {getMostRecentPublishedArticles} from '../services/articles.actions';

export const ArticlesCollectionContext = createContext({
    articles: [],
    isFetching: true,
    error: ""
});

const ArticlesCollectionProvider = ({children, countArticles}) => {
    const [articles, setArticles] = useState([]);
    const [isFetching, setFetchingStatus] = useState(true);
    const [error, setError] = useState("");
    useEffect(() => {
        getMostRecentPublishedArticles(countArticles)
            .then(response => {
                if (response.status === "fail") {
                    setError(response.message)
                } else if (response.data) {
                    setArticles(response.data);
                }
                setFetchingStatus(false);
            })
    }, [countArticles]);
    return(
        <ArticlesCollectionContext.Provider value={{isFetching, articles, error}}>
            {children}
        </ArticlesCollectionContext.Provider>
    )
}

export default ArticlesCollectionProvider;