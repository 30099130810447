import React from 'react';

import { ReactComponent as TwitterIcon } from './../../assets/twitter.svg';
import { ReactComponent as GithubIcon } from './../../assets/github.svg';
import { ReactComponent as DevDotToIcon } from './../../assets/dev-dot-to.svg';
import { ReactComponent as EmailIcon } from './../../assets/email.svg';


import './contact.styles.scss';


const Contact = () => (
    <div className="contact">
        <header className="page-header">
            <h1 className="article-title">Contact</h1>
        </header>
        <main className="contact__main page-content">
            <h2>If you would like to contact me, you can find me at:</h2>
            <ul className="contact__items">
                <li>
                    <a className="footer__social__link" href="https://twitter.com/rafaadev" target="_blank" rel="noreferrer"><TwitterIcon /></a>
                </li>
                <li>
                    <a className="footer__social__link" href="https://github.com/rafaagahbiche" target="_blank" rel="noreferrer"><GithubIcon /></a>
                </li>
                <li>
                    <a className="footer__social__link" href="https://dev.to/rafaagahbiche" target="_blank" rel="noreferrer"><DevDotToIcon /></a>
                </li>
                <li>
                    <a className="footer__social__link" href="mailto:ar.gahbiche@gmail.com" target="_blank" rel="noreferrer"><EmailIcon /></a>
                </li>
            </ul>
        </main>
    </div>
)

export default Contact;
