import React, {useState, useContext} from 'react';
import {CategoriesContext}  from './../../providers/categories.provider';

import './categories-selection.styles.scss'

const CategoriesSelection = () => {
    const {allCategories, selectedCategories, selectCategories} = useContext(CategoriesContext);
    const [allIsOn, setAllIsOn] = useState(true);
    let categoriesCopy = [...selectedCategories];
    
    const onCategorySelected = (e) => {
        e.preventDefault();
        const newEntry = e.target.innerText;
        if (newEntry === "all") {
            categoriesCopy = [...allCategories]
            setAllIsOn(true);
        } else if (allIsOn === true) {
            categoriesCopy = [newEntry]
            setAllIsOn(false);
        } else {
            if (categoriesCopy.includes(newEntry)) {
                categoriesCopy = categoriesCopy.filter(x => x !== newEntry);
                if (categoriesCopy.length === 0) {
                    categoriesCopy = [...allCategories]
                    setAllIsOn(true);
                } 
            } else {
                categoriesCopy.push(newEntry);
                if (categoriesCopy.length === allCategories.length) {
                    setAllIsOn(true);
                } 
            }
        }
        selectCategories(categoriesCopy);
    }
    return (
        <div className="categories-selection">
            <button onClick={onCategorySelected} key="all" 
                                                    className={allIsOn 
                                                    ? "btn categories-selection__item categories-selection__selected"
                                                    : "btn categories-selection__item"}>all</button>
            {allCategories.map(c => <button onClick={onCategorySelected} key={c} 
                                                    className={!allIsOn && selectedCategories.includes(c) 
                                                    ? "btn categories-selection__item categories-selection__selected"
                                                    : "btn categories-selection__item"} >{c}</button> )}
        </div> 
    )
}

export default CategoriesSelection;
