import React from 'react';

import { ReactComponent as TwitterIcon } from './../../assets/twitter.svg';
import { ReactComponent as GithubIcon } from './../../assets/github.svg';
import { ReactComponent as DevDotToIcon } from './../../assets/dev-dot-to.svg';

import './footer.styles.scss';

const Footer = () => (
    <footer className="footer">
        <div className="footer__social">
            <a className="footer__social__link" href="https://twitter.com/rafaadev" target="_blank" rel="noreferrer">
                <TwitterIcon />
            </a>
            <a className="footer__social__link" href="https://github.com/rafaagahbiche" target="_blank" rel="noreferrer">
                <GithubIcon />
            </a>
            <a className="footer__social__link" href="https://dev.to/rafaagahbiche" target="_blank" rel="noreferrer">
                <DevDotToIcon />
            </a>
        </div>
        <span>&#169; 2022 - RafaaGahbicheLab.dev</span>
    </footer>
)

export default Footer;
