import React, {useContext} from 'react';

import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {tomorrow} from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactMarkdown from "react-markdown";
import DateFormat from './../../utils/date-format';
import {ArticlePropertiesContext} from './../../providers/article-properties.provider';
import './article-content.styles.scss';

const ArticleContent = ({title, createdAt, categories}) => {
    const {article, isFetching, error} = useContext(ArticlePropertiesContext);
    title = title ?? (isFetching ? "" : article.title);
    createdAt = createdAt ?? (isFetching ? new Date() : article.createdAt);
    categories = categories ?? (isFetching ? [] : article.categories);

    const components = {
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match 
            ? (<SyntaxHighlighter style={tomorrow} language={match[1]} PreTag="div" children={String(children).replace(/\n$/, '')} {...props} />) 
            : (<code className={"inline-code " + className} {...props}>{children}</code>)
        }
    }
    return (
    <article>
        <header className="article-page__header page-header">
            <span>{DateFormat(createdAt)}</span>
            <h1 className="article-title">{title ?? article.title}</h1>
            <div className="article-page__categories">
                {categories?.map(c => 
                    <div className="article-page__category" key={c}><span className="category-text">{c}</span></div>
                )}
            </div>
        </header>
        <main className="article-page__content page-content">
        {isFetching 
            ?   <div className="loading"><span>Loading</span></div> : error ? <span>{error}</span>
            :   <div><p>{article.description}</p><ReactMarkdown components={components} children={article.content}  /></div>
        }
        </main>
    </article>
    )
}

export default ArticleContent;