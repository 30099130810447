import React from 'react';
import { Link } from 'react-router-dom';
import LatestStuffList from '../../components/latest-stuff-list/latest-stuff-list.component';
import ArticlesCollectionProvider from './../../providers/articles-collection.provider';

import './home.styles.scss';

const Home = () => (
    <div className="home">
        <div className="home__top">
            <div className="home__front">
                <div className="home__title">
                    <h1>Tech Collection Out Of The Lab</h1>
                </div>
                <div className="specifics">
                    <div className="single-specific">
                        <div className="single-specific__bulletpoint"></div>
                        <div className="single-specific__paragraph">
                            <p>A collection of my notes and experimentation in web development I thought worth to share and keep in a place where I can get back anytime I need.</p>
                        </div>
                    </div>
                    <div className="single-specific">
                        <div className="single-specific__bulletpoint"></div>
                        <div className="single-specific__paragraph">
                            <p>My stack is mainly .Net and C# but I also work on side projects like this blog using technologies like React, Nodejs or Angular.</p>
                        </div>
                    </div>
                </div>
                <div className="home__image"></div>
            </div>
            <div className="button">
                <a href="#latest-blogs" className="btn btn--big btn--simple btn--effect">See Latest</a>
            </div>
        </div>
        <div className="home__body">
            <div id="latest-blogs" className="latest">
                <h1>Stuff Written Lately</h1>
                <ArticlesCollectionProvider countArticles={3}>
                    <LatestStuffList />
                </ArticlesCollectionProvider>
                <div className="button">
                    <Link to={"/articles"} className="btn btn--big btn--simple btn--effect">See More</Link>
                </div>
            </div>
        </div>
    </div>
);
    

export default Home;